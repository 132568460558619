<script>
    import { _ } from 'svelte-i18n'
    import { global } from '$src/state.svelte.js'
    import LoginRequiredModal from '$lib/modal/LoginRequiredModal.svelte'
    import HelpButton from '$lib/button/HelpButton.svelte'

    let {
        authnWithManaged = false,
        usePersonalAccount = $bindable(false),
        contactLoginSuccess = () => {},
        handleContactError = () => {},
        continueWithEthereumExtension = () => {},
        continueWithProvider = () => {},
    } = $props()

    let authnWithManagedModal = $state(false)
</script>

{#if authnWithManagedModal}
    <LoginRequiredModal
        {contactLoginSuccess}
        {handleContactError}
        {continueWithEthereumExtension}
        {continueWithProvider}
        label={$_('To use a personal account')}
        providers={global.data?.managed}
        accountToUse="personal"
        close={() => (authnWithManagedModal = false)}
    />
{/if}

<button
    onclick={() => {
        if (authnWithManaged) {
            authnWithManagedModal = true
        } else {
            usePersonalAccount = !usePersonalAccount
        }
    }}
    class="mb-2 inline-flex items-center space-x-1 text-sm font-medium"
>
    <span
        >{usePersonalAccount
            ? $_('Hide personal account')
            : $_('Use a personal account')}</span
    >
    <HelpButton
        ariaLabel="Help"
        content={$_(
            'Providers you can use to log in or recover your Hellō Wallet',
        )}
        placement="bottom"
    />
</button>
