<script>
    import { _, locale } from 'svelte-i18n'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import VerifyEmail from '$lib/VerifyEmail.svelte'
    import MailIcon from '$lib/icon/MailIcon.svelte'
    import LoginPreferred from '$lib/login/LoginPreferred.svelte'
    import LoginManaged from '$lib/login/LoginManaged.svelte'

    let {
        login_hint = {},
        continueWithProvider = () => {},
        contactLoginSuccess = () => {},
        handleContactError = () => {},
        handleManagedEmailSuccess = () => {},
        continueWithEthereumExtension = () => {},
    } = $props()

    const isManaged = $derived(!!login_hint.managed)
    const isManagedEmail = $derived(!!login_hint.managed?.email)
    const isPreferred = $derived(!!login_hint.preferred)
</script>

{#if isManaged}
    {#if isManagedEmail}
        {@const managedEmail = login_hint.managed.email}
        <!-- TBD REFACTOR - USE LOGINMANAGED COMPONENT -->
        <div class="btn-border hover-none relative h-auto rounded-md px-4 pt-4">
            <button class="mb-2 flex w-full items-center justify-start">
                <MailIcon size="lg" />
                <div
                    class="flex flex-col {$locale && $locale.startsWith('ar')
                        ? 'mr-4 text-right'
                        : 'ml-4 text-left'}"
                >
                    <span>
                        {$_('Continue with Email')}
                    </span>
                    <span>{managedEmail}</span>
                </div>
            </button>

            <div class="pb-4 pt-1">
                <VerifyEmail
                    email={managedEmail}
                    login
                    disabled
                    accountSelected="managed"
                    accountToUse="managed"
                    success={contactLoginSuccess}
                    error={handleContactError}
                />
            </div>
        </div>
    {:else}
        <LoginManaged
            accountSelected="managed"
            accountToUse="managed"
            accounts={[login_hint.managed]}
            showUseDifferentManaged={false}
            {continueWithProvider}
            {contactLoginSuccess}
            {handleContactError}
            {handleManagedEmailSuccess}
        />
    {/if}
{:else if isPreferred}
    <LoginPreferred
        accountSelected="personal"
        accounts={[login_hint.preferred]}
        showOtherWaysToLogin={false}
        {contactLoginSuccess}
        {handleContactError}
        {continueWithEthereumExtension}
        {continueWithProvider}
    />
{:else if login_hint.email}
    <div class="btn-border hover-none relative h-auto rounded-md px-4 pt-4">
        {#if login_hint.provider?.slug}
            <LoginProvider
                login={true}
                onclick={() =>
                    continueWithProvider({
                        slug: login_hint.provider.slug,
                        body: {
                            login_hint: login_hint.email,
                            accountSelected: 'unknown',
                        },
                    })}
                provider={{
                    slug: login_hint.provider.slug,
                    user_name: login_hint.email,
                }}
                prefix="Continue with"
            />

            <span class="my-3 block text-center text-lg uppercase"
                >{$_('Or')}</span
            >
        {/if}

        <button class="mb-2 flex w-full items-center justify-start">
            <MailIcon size="lg" />
            <div
                class="flex flex-col {$locale && $locale.startsWith('ar')
                    ? 'mr-4 text-right'
                    : 'ml-4 text-left'}"
            >
                <span>
                    {$_('Continue with Email')}
                </span>
                <span>{login_hint.email}</span>
            </div>
        </button>

        <div class="pb-4 pt-1">
            <VerifyEmail
                email={login_hint.email}
                login
                disabled
                accountSelected="unknown"
                success={contactLoginSuccess}
                error={handleContactError}
            />
        </div>
    </div>
{/if}
